import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { Stack} from '@mui/material';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import greet from './greet.JPG'
import mail from './mail.gif'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function Messagebody() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack spacing={1.5} sx={{width:'100%',maxHeight:'400px',backgroundColor:'wight'}} >

    <Card  sx={{width:'100%',  overflowY:'scroll' }} >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            O
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title="Now you are osoul member ,, you are greet Wellcome "
        subheader="Today"
      />
      <CardMedia
        component="img"
        image={ greet}
        alt="greet"
      />
      <CardContent>
        <Typography variant="h6"  color="text.secondary">
          This is a greet news , you are Join osoul and we promiss you will engoy <br/>
          Now you can explore thuthants of the most wonderful designs and choose what you loved to save it in your liked page  .
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>One more step , to can download you first file from osoul graphic stock:</Typography>
          <Typography paragraph>
            Verifiy your E-mail adress.
          </Typography>
          <CardMedia
        component="img"
        height="250px"

        image={ mail}
        alt="greet"
      />
          <Typography paragraph>
           its an important step while you can make your account more secure and save <br />
           When press at verified Link from any page in the website we send an verifition Message to your mail wich used in registeration , you will go to 
           your mail inbox and open the message then follow verified botton .
          </Typography>
          <Typography paragraph>
            after verified your email you will get 25 coins for free wich may help you to download your firt trial design , and if you need more you have to recharge your wallet
          </Typography>
          <Typography>
            we here to help you , any time you have a question just type us on whats app No: 002-01124882882.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
    </Stack >

  );
}